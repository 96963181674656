<template>
  <div
    v-if="!popupNewsCookie && isAuthenticated && allowedPages"
    class="fixed bottom-24 left-20 flex flex-col gap-2 rounded-lg bg-primary-950 p-3.5 text-white drop-shadow-lg"
  >
    <div class="flex items-center gap-2">
      {{ $t('components.popover_news.title') }}

      <button
        type="button"
        :aria-label="$t('generic.actions.close')"
        @click="popupNewsCookie = '1'"
      >
        <span class="sr-only">{{ $t('generic.actions.close') }}</span>
        <BaseIcon icon="fal fa-xmark" />
      </button>
    </div>

    <img
      class="max-h-20 rounded-lg object-cover"
      :alt="$t('components.popover_news.title')"
      src="/images/novinka-popup.jpg"
      loading="lazy"
    >

    <NuxtLink
      to="https://www.zaslat.cz/novinky/Novinka-p-i-objedn-n-svoz-f0a6effecd014d77955fa2211cee1f27"
      target="_blank"
    >
      <BaseButton full-size>
        {{ $t('components.popover_news.button') }}
      </BaseButton>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
const popupNewsCookie = useCookie('popup-news')
const isAuthenticated = computed(() => useAuthStore().isAuthenticated)
</script>
